
import React from 'react';
import * as Router from 'react-router';
import * as ReactRedux from 'react-redux';

import * as Layout from './layout/Layout.js';
import Panel from '../components/Panel.js';
import ReduxSweetAlert from 'react-redux-sweetalert';

import { authenticate, validateToken } from './session/session.js';

// Redux
import reducers from './reducers.js';

// Routing
import OnboardedRoute from './user/OnboardedRoute.js';

// Modules
import Portal from './portal/Portal.js';
import Logout from './session/Logout.js';
import LoginForm from './session/LoginForm.js';
import RegisterForm from './session/RegisterForm.js';
import EmailConfirmation from './session/EmailConfirmation.js';
import UserPreferences from './user/Preferences.js';
import UserOnboarding from './user/Onboarding.js';
import About from './information/About.js';
import AboutGroningen from './information/AboutGroningen.js';
import PrivacyStatement from './information/PrivacyStatement.js';
//import AboutDrenthe from './information/AboutDrenthe.js';
import * as Events from './events/Events.js';
import EventOverview from './events/EventOverview.js';
import CategoryOverview from './categories/CategoryOverview.js';
import MainCategoryOverview from './categories/MainCategoryOverview.js';
import SubmitEvent from './user/EventSubmitter.js';
import SearchEvent from './events/EventSearch.js';
import NearbyEvents from './events/NearbyEvents.js';
import VenueDetails from './venues/VenueDetails.js';
import Explore from './blog/Explore.js';
import ContentPage from './blog/ContentPage.js';


export const update = (state, action) =>
    reducers.reduce((state, reducer) => reducer(state, action), state);

const PageNotFound = () =>
    <Panel>
        <h1>Page not found</h1>
        <p>
            The page you requested could not be found.
        </p>
    </Panel>
    ;

@Router.withRouter
@ReactRedux.connect(
    state => ({
        site: state.getIn(['app', 'site']),
        authUser: state.getIn(['session', 'auth', 'user']),
    }),
)
export class App extends React.PureComponent {
    componentDidMount() {
        const { dispatch, history } = this.props;
        
        // Initialize session
        if (typeof window !== 'undefined' && window && window.localStorage) {
            const storage = window.localStorage;
            
            if (storage.hasOwnProperty('auth')) {
                const auth = JSON.parse(storage.getItem('auth'));
                
                dispatch(authenticate({ auth }));
                
                // Validate the token (check if the session is still valid)
                dispatch(validateToken(auth.token));
            }
        }
        
        history.listen(() => {
            // Scroll to top on route change
            window.scrollTo(0, 0);
        });
    }
    
    render() {
        if (this.props.site.name === 'portal') {
            return <Portal/>;
        }
        
        return (
            <Router.Switch>
                <Router.Route exact path="/portal" component={Portal} />

                <Router.Route exact path="/logout" render={() =>
                    <Layout.LayoutPublic>
                        <Logout/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/login" render={() =>
                    <Layout.LayoutOnboarding>
                        <LoginForm/>
                    </Layout.LayoutOnboarding>
                }/>
                
                <Router.Route exact path="/email" render={() =>
                    <Layout.LayoutOnboarding>
                        <EmailConfirmation/>
                    </Layout.LayoutOnboarding>
                }/>

                <Router.Route exact path="/explore" render={() =>
                    <Layout.LayoutPublic>
                        <Explore/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/explore/:slug" render={({ match }) =>
                    <Layout.LayoutPublic>
                        <ContentPage slug={match.params.slug}/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/about" render={() =>
                    <Layout.LayoutPublic>
                        <About/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/about-groningen" render={() =>
                    <Layout.LayoutPublic>
                        <AboutGroningen/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/privacy-statement" render={() =>
                    <Layout.LayoutPublic>
                        <PrivacyStatement/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/register" render={() =>
                    <Layout.LayoutOnboarding>
                        <RegisterForm/>
                    </Layout.LayoutOnboarding>
                }/>
                
                <Router.Route exact path="/category/:parent/:child" render={() =>
                    <Layout.LayoutPublic>
                        <CategoryOverview/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/category/:parent" render={() =>
                    <Layout.LayoutPublic>
                        <MainCategoryOverview/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/event/:id" render={() =>
                    <Layout.LayoutPublic>
                        <EventOverview/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/user/preferences" render={() =>
                    <Layout.LayoutPublic>
                        <UserPreferences/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/submitevent" render={() =>
                    <Layout.LayoutPublic>
                        <SubmitEvent/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/user/onboarding" render={() =>
                    <Layout.LayoutOnboarding>
                        <UserOnboarding/>
                    </Layout.LayoutOnboarding>
                }/>
                
                <Router.Route exact path="/search/:query" render={() =>
                    <Layout.LayoutPublic>
                        <SearchEvent/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/nearby" render={() =>
                    <Layout.LayoutPublic>
                        <NearbyEvents/>
                    </Layout.LayoutPublic>
                }/>
                
                <Router.Route exact path="/venues/:id">
                    <Layout.LayoutPublic>
                        <VenueDetails/>
                    </Layout.LayoutPublic>
                </Router.Route>
                
                {/* Test page: to debug client-side error logs */}
                <Router.Route exact path="/error"
                    render={() => {
                        throw new Error("Test error!");
                    }}
                />
                
                <Router.Route render={() =>
                    <Layout.LayoutPublic>
                        <Router.Switch>
                            <OnboardedRoute exact path="/" render={() =>
                                <Events.Events/>
                            }/>
                            <OnboardedRoute exact path="/events" render={() =>
                                <Events.Events/>
                            }/>
                            
                            {/* Fallback: page not found */}
                            <Router.Route component={PageNotFound}/>
                        </Router.Switch>
                    </Layout.LayoutPublic>
                }/>
            </Router.Switch>
        );
    }
}
