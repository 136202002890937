import { url } from '../CpgAgent.js';

export const imageUrl = (
    process.env.IMAGE_URL && process.env.IMAGE_URL !== 'false' 
        ? process.env.IMAGE_URL
        : ''
);

//TODO later can we use the width, height and fit attributes to crop the image for faster page loads based on the size of the screen
export const convertPath = (path) => {
    return `${imageUrl || url}/backend/image?src=${path}`
};

export const getFallbackImage = () => {
    return `${imageUrl || url}/backend/image?src=uploads/placeholder-yellow.png&w=350&h=190&fit=crop`
};
