
import $uri from 'uri-tag';
import React from 'react';
import * as ReactRedux from 'react-redux';
import * as Router from 'react-router';
import { Helmet } from 'react-helmet';

import inject from '../../util/di/inject.js';

import agent from '../../services/CpgAgent.js';
import Collection from '../../util/models/Collection.js';
import Event from '../../models/Event.js';
import { makeEntry } from '../../util/api/collection_util.js';
import Image from '../../models/Image.js';

import { convertPath, getFallbackImage } from '../../services/model/ImageService.js';

import Slider from 'react-slick';
import EventsSliderList from '../events/EventsSliderList.js';
import EventsSlider from '../events/EventsSlider.js';
import SitesService from '../../services/SitesService.js';


const sliderConfig = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: false,
  arrows: false,
  draggable: false,
  fade: true,
  speed: 1000,
};

@Router.withRouter
@ReactRedux.connect(
  state => ({
    auth: state.getIn(['session', 'auth']),
  }),
)
@inject({
  sites: 'cpg.sites',
})
export default class VenueDetails extends React.PureComponent {
  state = {
    isLoading: true,
    errorMessage: null,
    
    sliderActive: false,
    
    slug: null,
    name: null,
    
    location: {
      address: null,
      postcode: null,
      city: null,
    },
    
    description: null,        
    events: null,
    website: null,
    facebook_link: null,
    ticketing_link: null,
  };
  
  getBaseUrl() {
    return this.props.sites.getBaseUrl();
  }

  goto = location => {
    window.open(location, '_blank');
  };
  
  loadVenue() {
    const venue = this.props.match.params.id;
    this.setState({
      isLoading: true
    });
    agent.get('/location/' + encodeURIComponent(venue))
      .then(response => {
        this.showVenue(response.body);
      })
      .catch(reason => {
        this.setState({ errorMessage: "Something went wrong while loading events." });
      });
  }
  
  genGoogleMapsUrl(location) {
    const queryItems = [
      location.address,
      location.postcode,
      location.city,
    ];
    
    const query = queryItems
      .filter(item => item != null && item !== '')
      .join(',');
    
    return $uri`https://maps.google.com/maps?q=${query}`;
  }
  
  showVenue({ slug, name, address, postcode, city, description, images, events, website, facebook_link, ticketing_link }) {
    const eventsCollection = new Collection(Event, { status: 'ready' }, events.map(event => {
      const images = new Collection(Image, { status: 'ready' },
        event.images.map(image => {
          return makeEntry(Image, image);
        })
      );
      return makeEntry(Event, { ...event, images });
    }));
    
    this.setState({
      isLoading: false,
      slug,
      name,
      images,
      location: {
        address,
        postcode,
        city,
      },
      description,
      events: eventsCollection,
      website,
      facebook_link,
      ticketing_link
    });
  }
  
  componentDidMount() {
    this.loadVenue();
  }
  
  render() {
    const site = new SitesService().getSite(window.location);

    if (this.state.isLoading) {
      return (            
        <div className="content-wrapper">
          <header className="container-fluid jumbotron jumbotron-venue loading">
            <div className="item-detail-wrapper row">    
              <div className="item-more-details">    
                <div className="row item-details-inner-wrapper">
                  <div className="col-xs-12 col-sm-8 col-lg-6 col-xl-5 item-details-content-wrapper vertical-align-xs">
                    {/* Loading state content */}
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      );
    }
    
    // const imagesArray = [
    //     { path: '../data/uploads/33130972-723080237866979-1930277994971004928-o-2.jpg' },
    // ];
    
    const venueImages = this.state.images;
    const imagesArray = Array.isArray(venueImages) ? venueImages : venueImages.toArray();
    
    const images = imagesArray.length !== 0
      ? imagesArray.map((image, index) => (
        <div key={index}
          className="background-image-item lazyload"
          data-bgset={
          `${convertPath(image.path)}&w=600&h=270&fit=crop-center [(max-width: 600px)] | ${convertPath(image.path)}&w=768&h=416&fit=crop-center [(max-width: 992px)] | ${convertPath(image.path)}&w=1220&h=536&fit=crop-center` 
          }
        />
      ))
      : [
        <div key="placeholder" className="background-image-item"
           style={{ backgroundImage: `url(${getFallbackImage()}&w=1220&h=536&fit=crop&fit=crop-center)` }}
        />
      ];
    
    return (
      <div>
        <Helmet>
          <body className={`page-venue-detail ${this.state.sliderActive ? 'slider-open' : ''}`}/>
          <meta name="description" content={`Events and information for ${this.state.name}, ${this.state.location.address} ${this.state.location.city} - Here & Now in ${site.title}.`} />
          <title>{`Events for '${this.state.name}' in ${this.state.location.city}`}</title>
          
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={$uri`${$uri.raw(this.getBaseUrl())}/venue/${this.state.slug}`}/>
          <meta property="og:title" content={`Events for '${this.state.name}' in ${this.state.location.city}`}/>
          <meta property="og:description" content={`Events and information for ${this.state.name}, ${this.state.location.address} ${this.state.location.city} - Here & Now in ${site.title}.`}/>
          {/*
          <meta property="og:image" content={
            imagesArray.length === 0
              ? ''
              : process.env.API_URL + $uri`/image?src=${imagesArray[0].path}&w=800&h=500&fit=crop&fit=crop-center`
          }/>
          <meta property="og:image:width" content="800"/>
          <meta property="og:image:height" content="500"/>
          */}
        </Helmet>
        
        {this.state.errorMessage !== null &&
          <div className="alert alert-danger" role="alert">
            <strong>Error:</strong>
            {' '}
            {this.state.errorMessage}
          </div>
        }
        
        <header className="container-fluid jumbotron jumbotron-venue loading margin60B">   
          <div className="item-detail-wrapper row">
            <div className="item-more-details">
              <div className="item-details-slider-wrapper">
                {images && images.length > 0 &&
                  <Slider {...sliderConfig}
                    className="background-image-slider col-sm-offset-0 col-md-offset-2 col-lg-offset-4 col-xl-offset-4"
                  >
                    {images}
                  </Slider>
                }
              </div>
              
              <div className="item-details-inner-wrapper">
                <div className="col-xs-12 col-ms-12 col-sm-10 col-lg-7 col-xl-6 item-details-content-wrapper vertical-align-xs">
                  <div className="item-details-content clearfix">
                    <h1 title={this.state.name}>
                      {_.truncate(this.state.name, {
                        'length': 72
                      })}
                    </h1>
                    
                    {this.state.location.address && this.state.location.postcode && this.state.location.city &&
                    <p>
                        <strong className="detail-label">Where:</strong>
                        {this.state.location.address}, {this.state.location.postcode}, {this.state.location.city}
                        <span className="item-maps-link">
                          <a target="_blank" href={this.genGoogleMapsUrl(this.state.location)}>
                            (Open in Google Maps)
                          </a>
                        </span>
                    </p>
                    }
                    <p>
                      <strong className="detail-label">Details:</strong>
                      Events and information for {this.state.name}.
                    </p>
                    
                    {this.state.description && 
                      <p>
                        {this.state.description} 
                      </p>
                    }
                    
                    <div className="margin20T action-buttons btn-group hidden-xs hidden-sm" role="group" aria-label="Action buttons">
                    {this.state.website && 
                      <div className="btn btn-primary"
                        onClick={() => this.goto(this.state.website)} 
                        role="button">
                        <span className="icon icon-link" aria-hidden="true" aria-label="Website icon" />
                        Website
                      </div>
                      }
                      {this.state.facebook_link && 
                      <div className="btn btn-primary"
                        onClick={() => this.goto(this.state.facebook_link)}
                        role="button">
                        <span className="icon icon-social-facebook" aria-hidden="true" aria-label="Facebook icon"/>
                        Facebook
                      </div>
                      }
                      {this.state.ticketing_link && 
                      <div className="btn btn-primary"
                        onClick={() => this.goto(this.state.ticketing_link)}
                        role="button">
                        <span className="icon icon-control-play" aria-hidden="true" aria-label="Buy tickets icon"/>
                        Buy tickets
                      </div>
                      }
                    </div>

                    <div className="margin20T action-buttons visible-xs visible-sm" role="group" aria-label="Action buttons">
                      {this.state.website && 
                        <a href="#" className="btn btn-outline btn-primary btn-block"
                          onClick={() => this.goto(this.state.website)}
                          role="button">
                          <span className="icon icon-link" aria-hidden="true" aria-label="Website icon"/>
                          Website
                        </a>
                      }
                      {this.state.facebook_link && 
                        <a className="btn btn-outline btn-primary btn-block margin20T"
                          onClick={() => this.goto(this.state.facebook_link)}
                          role="button">
                          <span className="icon icon-social-facebook" aria-hidden="true" aria-label="Add to list icon"/>
                          Facebook
                        </a>
                      }
                      {this.state.ticketing_link && 
                        <a className="btn btn-outline btn-primary btn-block margin20T"
                          onClick={() => this.goto(this.state.ticketing_link)}
                          role="button">
                          <span className="icon icon-control-play" aria-hidden="true" aria-label="Buy tickets icon"/>
                          Buy tickets
                        </a>
                      }
                    </div>
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        
        {/*
        <div>
          <h2 className="events-category-title">
            Current events
          </h2>
          {this.state.events.entries.size === 0 &&
            <p className="margin0B">
              There are no current events at {this.state.name}.
            </p>
          }
          
          {this.state.events && this.state.events.map((event) => (
            <div
              key={event.slug}
              className="col-xs-12 col-ms-6 col-sm-4 col-md-3 category-event-item"
              onClick={() => this.props.history.push(`/event/${event.slug}`)}
            >
              <EventsSliderElement
                key={event.id}
                event={event}
                onHover={() => {}} // TODO
                update={() => {}} // TODO
              />
            </div>
          )).toArray()}
        </div>
        */}
        
        <EventsSliderList onActivate={sliderActive => { this.setState({ sliderActive }); }}>
          <EventsSlider
            disabled={null}
            auth={this.props.auth}
            update={() => {}}
            title={
              <span className="nolink">Events</span>
            }
            events={this.state.events}
          />
        </EventsSliderList>
      </div>
    );
  }
}
