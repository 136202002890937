
import _ from 'lodash';
import Imm from 'immutable';

import Schema from '../util/models/schema.js';
import Entity from '../util/models/Entity.js';
import Collection from '../util/models/Collection.js';

export default class Location extends Entity {
  static key = { id: Schema.text };
  static schema = {
    name: Schema.text,
    slug: Schema.text,
    
    address: Schema.text,
    postcode: Schema.text,
    city: Schema.text,
    
    coords: [Schema.number, Schema.number],
    // Lazy load Event to prevent circular import that breaks the production server
    events: new Collection(require('./Event.js').default),
  };

  constructor(...args) {
    super(Location.schema, ...args);
  }
};
