
import _ from 'lodash';
import Imm from 'immutable';
import match from '@mkrause/match';
import uri from '../util/tag_uri.js';

import * as Api from '../util/api/Api.js';
import agent from './CpgAgent.js';
import * as CategoryService from './api/CategoryService.js';

import Collection from '../util/models/Collection.js';
import { makeEntry } from '../util/api/collection_util.js';

// Models
import User from '../models/User.js';
import Event from '../models/Event.js';
import Image from '../models/Image.js';
import Location from '../models/Location.js';
import Category from '../models/Category.js';
import * as EventsService from './api/EventsService.js';
import * as EventGroupService from './api/EventGroupService.js';
import EventGroup from '../models/EventGroup.js';
import Menu from '../models/Menu.js';
import ContentPage from '../models/ContentPage.js';


export const parseEventResponse = eventResponse => {
    if (eventResponse instanceof Event) {
        return eventResponse;
    }
    
    // Parse event data to an Event instance
    return {
        ...eventResponse,
        // We want to use the slug as the key
        id: eventResponse.slug,
        // Take the images (array) and create an Image collection
        images: new Collection(Image, { status: 'ready' }, eventResponse.images.map(item => makeEntry(Image, item))),
    };
};

export const parseEventListResponse = eventListResponse => {
    return new Collection(Event, { status: 'ready' },
        eventListResponse.map(eventResponse => makeEntry(Event, parseEventResponse(eventResponse)))
    );
};

export const parseLocationResponse = locationResponse => {
    if (locationResponse instanceof Event) {
        return locationResponse;
    }
    
    /*
    const center = match(window.site.name, {
        groningen: [53.21, 6.55],
        friesland: [53.20, 5.742],
        drenthe: [52.98, 6.47],
    });
    */
    
    // Parse event data to an Event instance
    return {
        ...locationResponse,
        // We want to use the slug as the key
        id: locationResponse.slug,
        
        // TEMP: add randomized coord until we get the real data
        // coords: center.map(coord => coord + 0.1 * (Math.random() - 0.5)),
        coords: locationResponse.latitude === null || locationResponse.longitude == null
            ? null
            : [locationResponse.latitude, locationResponse.longitude],
        
        // Take the images (array) and create an Image collection
        events: new Collection(Event, { status: 'ready' }, locationResponse.events.map(item => {
            const entry = makeEntry(Event, item);
            entry[1] = entry[1]
                .set('images', new Collection(Image, { status: 'ready' }, item.images.map(item => makeEntry(Image, item))))
                .set('location', new Location({ ...locationResponse, events: [] }, { status: 'ready' }));
            return entry;
        })),
    };
};

export default Api.restApi(agent, {
    store: ['app'],
    resources: {
        users: Api.collection(User, {
            uri: uri`/users`,
            store: ['app', 'users'],
            methods: {},
        }),
        locations: Api.collection(Location, {
            uri: uri`/locations`,
            store: ['app', 'locations'],
            methods: {
                list: (spec) => {
                    return agent.get(spec.uri, { limit: 9999 })
                        .then(response => {
                            return {
                                ...response,
                                body: response.body._embedded.items.map(parseLocationResponse),
                            };
                        });
                },
            },
        }),
        categories: Api.collection(Category, {
            uri: uri`/categories/`,
            store: ['app', 'categories'],
            methods: {
                list: (spec, { isFavorite = false } = {}) => {
                    if (isFavorite) {
                        return CategoryService.getFavoriteCategories(uri, spec, isFavorite);
                    } else {
                        return CategoryService.getCategories(uri, spec);
                    }
                },
            },
        }),
        events: Api.collection(Event, {
            uri: uri`/events`,
            store: ['app', 'events'],
            methods: {
                list: (spec, { isPopular = false, categoryId = undefined, isFavorite = false, isFeatured = false, limit = 50 } = {}) => {
                    if (categoryId) return EventsService.getEventsFromCategory(uri, spec, categoryId, { limit });
                    
                    // if(isPopular) return EventsService.getPopularEvents(uri, spec, isPopular);
                    // else if(isFavorite) return EventsService.getFavoriteEvents(uri, spec, isFavorite);
                    // else if(isFeatured) return EventsService.getFeaturedEvents(uri, spec, isFeatured);
                    
                    return Promise.resolve();
                },
                update: (spec, index, entity, { addFavorite = false } = {}) => {
                    if (addFavorite) {
                        return EventsService.addFavoriteEvent(uri, spec, index, entity);
                    }
                    return Promise.resolve();
                },
            },
        }),
        eventGroups: Api.collection(EventGroup, {
            uri: uri`/event-groups/`,
            store: ['app', 'eventgroups'],
            methods: {
                list: (spec, { eventId = undefined, home = undefined, limit = 50 } = {}) => {
                    if (eventId) {
                        return EventGroupService.getHome(eventId);
                    } else if (home) {
                        return EventGroupService.getHome();
                    } else {
                        return EventGroupService.getEventGroups({ limit });
                    }
                },
            },
        }),
        
        // APIs that depend on the current auth user
        
        eventFavorites: Api.collection(Event, {
            uri: uri`/favorites/events`, //XXX do not use trailing slash, backend requires that there is none
            store: ['app', 'authUser', 'eventFavorites'],
            methods: {
                list: (spec, {} = {}) => {
                    return agent.get(spec.uri)
                        .then(response => {
                            // Use slug as ID
                            const events = response.body; // As array
                            return {
                                ...response,
                                body: events.map(parseEventResponse),
                            };
                        });
                },
                create: (spec, event) => {
                    // To add an event as favorite, the API requires an empty POST request to
                    // `/favorites/events/{slug}`
                    // The response should be the event itself, so it gets added to the store
                    return agent.post(`${spec.uri}/${event.slug}`).send()
                        .then(() => ({ body: { ...event.toJS(), id: event.slug, images: event.images } }));
                },
            },
        }),
        
        menu: Api.collection(Menu, {
            uri: uri`/event-groups/`,
            store: ['app', 'menu'],
            methods: {
                list: (spec, { limit = 50 } = {}) => {
                    return EventGroupService.getEventGroups({ limit });
                },
            },
        }),
        
        contentPages: Api.collection(ContentPage, {
            uri: uri`/content`,
            store: ['app', 'contentPages'],
            methods: {
                list: (spec, {} = {}) => {
                    return agent.get(`${spec.uri}/all`)
                        .then(response => {
                            // Filter out privacy statements
                            // TODO: make this filter mechanism more elegant.
                            const contentPages = response.body.filter(item => {
                                if (!item.content_key.match(/privacy-statement/)) {
                                    return item;
                                }
                            });
                            return {
                                ...response,
                                body: contentPages,
                            };
                        });
                },
                // get: (spec, {} = {}) => {
                //     return agent.get(`${spec.uri}/all`)
                //         .then(response => {});
                // },
            },
        }),
    },
});
