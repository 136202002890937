
import cx from 'classnames';

import React from 'react';
import moment from 'moment';
import * as Router from 'react-router';
import { Link } from 'react-router-dom';
import * as ReactRedux from 'react-redux';
import { backendUrl } from '../../services/CpgAgent.js';
import inject from '../../util/di/inject.js';


@inject({
    sites: 'cpg.sites',
})
@ReactRedux.connect(
    state => ({
        session: state.getIn(['session']),
    }),
)
@Router.withRouter
export default class PopUp extends React.PureComponent {
    state = {
        collapsed: false,
    };

    constructor(props) {
        super(props);
        
        if (typeof window !== undefined) {
            const collapsedUntil = window.localStorage.getItem('collapsedPopUp');
            if (collapsedUntil !== null && moment().unix() < parseInt(collapsedUntil)) {
                this.state.collapsed = true;
            }
        }
    }
    
    handleFacebook = () => {
        const { history } = this.props;
        
        const baseUri = new URL(window.location).origin;
        const registerUri = baseUri + history.createHref({ pathname: '/register' });
        
        window.location.href = `${backendUrl}/oauth/facebook?return_url=${registerUri}`;
    };
    
    // Toggle function to open and close the pop up
    togglePopUp = () => {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem('collapsedPopUp', moment().add(7, 'days').unix());
        }
        this.setState(st => ({ collapsed: !st.collapsed }));
    };
    
    render() {
        // Disable when server side rendering is active
        if (typeof global !== 'undefined' && global.ssr !== undefined) {
            return null;
        }

        const { collapsed } = this.state;
        const auth = this.props.session.auth;
        if (auth !== null) {
            return null;
        }
        
        return (
            <div className="popup">
                <a onClick={this.togglePopUp}>
                    <h1>
                        Join Here & Now
                        {' '}
                        <span className={cx('chevron', collapsed && 'collapsed')}/>
                    </h1>
                </a>
                
                {!collapsed &&
                    <div className="hidden-part">
                        <div className="line"/>
                        <p>Register with Here & Now and never miss another event!</p>

                        {/* <div className="popup-button">
                            <a className="facebook-login" title="Register with Facebook"
                                onClick={evt => { evt.preventDefault(); this.handleFacebook(); }}
                            >
                                <span>Register with Facebook</span>
                            </a> 
                        </div>*/}
                        <div className="email-login" title="Register with e-mail">
                            <Link to="/register">Register with e-mail</Link>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
