
import _ from 'lodash';
import Imm from 'immutable';

import Schema from '../util/models/schema.js';
import Collection from '../util/models/Collection.js';
import Entity from '../util/models/Entity.js';

import Image from './Image.js';

export default class Event extends Entity {
    static key = { id: Schema.text };
    static schema = {
        id: Schema.text,
        
        slug: Schema.text,
        name: Schema.text,
        start_date: Schema.text,
        end_date: Schema.text,
        popular: Schema.text,
        description: Schema.text,
        summary: Schema.text,
        website: Schema.text,
        ticket_link: Schema.text,
        price: Schema.text,
        favorite: Schema.text,
        categoryId: Schema.text,
        featured: Schema.text,
        facebook_link: Schema.text,
        // Lazy load Location to prevent circular import that breaks the production server
        location: require('./Location.js').default,
        images: new Collection(Image),
        recurring_label: Schema.text,
        recurring_interval: Schema.text,
        recurring_until: Schema.text,
    };

    constructor(...args) {
        super(Event.schema, ...args);
    }
}
