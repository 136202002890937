
import React from 'react';
import PropTypes from 'prop-types';
import * as ReactRedux from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import api from '../../services/CpgApi.js';
import * as Router from 'react-router';
import CategorySliderElement from './CategorySliderElement.js';


@Router.withRouter
@ReactRedux.connect(
    state => ({
        eventGroups: state.getIn(['app', 'eventgroups']),
    }),
)
export default class MainCategoryOverview extends React.Component {
    retrieveData = () => {
        this.props.dispatch(api.eventGroups.list({ limit: 9999 }));
    };
    
    componentDidMount() {
        this.retrieveData();
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.match.params.parent !== prevProps.match.params.parent) {
            this.retrieveData();
        }
    }
    
    render() {
        const { eventGroups, match, history } = this.props;
        const { parent } = match.params;
        const mainEventGroup = eventGroups.get(parent);
        return (
            <div>
                {!mainEventGroup
                    ?
                        <div className="container events-category-wrapper">
                            <div className="row">
                                <header className="col-md-12">
                                    {eventGroups.meta.status === "pending"
                                        ?
                                            <h2 className="events-category-title">
                                                Loading...
                                            </h2>
                                        :
                                            <h2 className="events-category-title">
                                                <span className="current-category">Category '{parent}' not found</span>
                                            </h2>
                                    }
                                </header>
                            </div>
                        </div>
                    : 
                        <div className="container events-category-wrapper main-events-category">
                            <Helmet>
                                <body className="page-category-main-overview"/>
                                <meta name="description" content={mainEventGroup.name}/>
                                <title>{mainEventGroup.name}</title>
                            </Helmet>
                            
                            <div className="row">
                                <header className="col-md-12">
                                    <h2 className="events-category-title">
                                        <span className="current-category">{mainEventGroup.name}</span>
                                    </h2>
                                </header>
                            </div>
                            <div className="row">
                                {!mainEventGroup.children
                                    ?
                                        <h1> No categories are found </h1>
                                    :
                                        mainEventGroup.children.map((eventGroup, eventGroupId) =>
                                            <div
                                                key={eventGroupId}
                                                className="col-xs-6 col-sm-4 col-md-3 category-event-item"
                                                onClick={() => {
                                                    history.push(`/category/${mainEventGroup.get('slug')}/${eventGroup.get('slug')}`);
                                                }}
                                            >
                                                <CategorySliderElement
                                                    key={eventGroupId}
                                                    mainEventGroup={mainEventGroup}
                                                    eventGroup={eventGroup}
                                                />
                                            </div>
                                    ).toArray()
                                }
                            </div>
                        </div>
                }
            </div>
        );
    }
}
