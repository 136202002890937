
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import inject from '../../util/di/inject.js';
import SitesService from '../../services/SitesService.js';


@inject({
    sites: 'cpg.sites',
})
export default class Footer extends React.PureComponent {
    
    switchToSiteUnlessAlreadyThere(siteName) {
        const site = new SitesService().getSite(window.location);
        if (site.name !== siteName) {
            this.props.sites.switch({ name: siteName });
        }
    }

    render() {
        const site = new SitesService().getSite(window.location);

        return (
            <footer className="page-footer navbar navbar-static-bottom">
                <div className="page-footer-top">
                    <div className="container-fluid">
                        {site.name === 'groningen' && 
                            <div className="row vertical-align-sm">
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="http://www.rug.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Logo University of Groningen"
                                            src={require('../../style/img/footer-rug.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.hanze.nl/eng" className="footer-link">
                                        <img className="img-responsive" alt="Logo Hanze University Groningen"
                                            src={require('../../style/img/footer-hanze.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://northsearegion.eu/" className="footer-link footer-link-lg">
                                        <img className="img-responsive" alt="Logo Interreg North Sea Region"
                                            src={require('../../style/img/footer-interreg.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.umcg.nl/EN/corporate/paginas/default.aspx" className="footer-link">
                                        <img className="img-responsive" alt="Logo UMCG Groningen"
                                            src={require('../../style/img/footer-umcg.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://gemeente.groningen.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Logo City of Groningen"
                                            src={require('../../style/img/footer-gemeente.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a href="https://www.provinciegroningen.nl/servicelinks-provincie-groningen/english/" target="_blank" className="footer-link">
                                        <img className="img-responsive" alt="Logo Province Groningen"
                                            src={require('../../style/img/footer-provincie.png')}
                                        />
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="container-fluid page-footer-bottom">
                    <div className="row no-gutters" style={{display: 'flex'}}>
                        <div className="col-xs-4 col-sm-4 col-sm-offset-0 col-md-2 col-md-offset-3">
                            <a className="navbar-brand groningen" title="Here & Now in Groningen"
                                onClick={() => this.switchToSiteUnlessAlreadyThere('groningen')}
                            >
                                <img className="svg img-responsive"
                                    src={require('../../style/img/svg/logo-here-now-groningen.svg')}
                                />
                            </a>
                        </div>
                    </div>
                    <div className="footer-container">
                        <Link to="/about" className="footer-nav">About HereAndNow</Link>
                    
                        {site.name === 'groningen' &&
                            <Link to="/about-groningen" className="footer-nav">About Groningen</Link>
                        }
                    
                        <Link to="/submitevent" className="footer-nav">Submit an Event</Link>
                    
                        <a
                            href="https://groningen.nl/internationals"
                            className="footer-nav"
                            target="_blank"
                        >
                            Internationals
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}
